import { Header } from "../../sections/Header"
import { About } from "../../sections/About"
import { FAQ } from "../../sections/FAQ"
import { Prognose } from "../../sections/Prognose"
import { Calculator } from "../../sections/Calculator"
import { Roadmap } from "../../sections/Roadmap"
import { Info } from "../../sections/info"
import { Contact } from "../../sections/Contact"

export const HomePage = () => {
	return (
		<>
			<Header />
			<About />
			<Prognose />
			<Calculator />
			<Roadmap />
			<Info />
			<Contact />
		</>
	);
}