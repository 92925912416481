import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { List } from "../../components/common/List"
import { Row } from "../../components/common/Row"
import { Section } from "../../components/common/Section"
import { Space } from "../../components/common/Space"
import { ContentBlock } from "../../components/common/ContentBlock"
import { Image } from "../../components/common/Image"
import { Card } from "./Card"
import { scrollTo } from "../../utils/helpers"
import { TSParticles } from '../../components/common/Particles'

import { Button } from "../../components/common/Button"

import './index.scss'
import { Reveal } from "../../components/Reveal"
import { useNavigate } from "react-router-dom"


export const About = () => {
    const navigate = useNavigate()

    return (
        <Section id="about" background={'g_2'} color={'primary_color'}>
            <TSParticles />
            <Container>
                
                <Row justifyContent={'center'}>

                    <Column flex={{ mobile: 1, desktop: .8 }} alignItems='center' justifyContent='flex-start' color={'white'}>
                        
                        <Space size={5} desktop />
                        <Reveal><h2>Herzlich Willkommen bei <strong>Quick Credit Schweiz Immobilien!</strong></h2></Reveal>
                        
                        <Space size={2} />
                        
                        <Reveal><p>
                            Wir freuen uns, Sie auf unserer Webseite begrüssen zu dürfen, wo wir Ihnen die besten Renditeimmobilien in Deutschland präsentieren. Unsere Expertise liegt in der sorgfältigen Auswahl und Verwaltung von Liegenschaften, die derzeit die höchsten Profite bieten.
                        </p></Reveal>
                        <Reveal><p>
                            Bei uns erhalten Sie nicht nur Zugang zu erstklassigen Immobilien, sondern auch zu 100 % Finanzierungen durch unsere Partnerschaften mit den renommiertesten Deutschen Banken. Unser umfassender Service umfasst die Organisation der Finanzierung sowie die Verwaltung der vermieteten Renditeimmobilien durch unsere Hausverwaltung und Sondereigentumsverwaltung. Wir bieten Ihnen ein breites Spektrum an Immobilien, von kleinen Objekten bis hin zu Mehrfamilienhäusern, und sichern Ihnen die besten Zinsen bei den Finanzierungen.
                        </p>
                        </Reveal>
                        <Reveal>
                            <p>
                                <strong>
                                Vertrauen Sie auf unsere langjährige Erfahrung und unser Engagement, um Ihre Investitionen erfolgreich und sorgenfrei zu gestalten.</strong>
                            </p>
                        </Reveal>
                        

                    </Column>

                </Row>
                <Space size={10} />
                
                


                <Row justifyContent={'center'}>
                    <Column flex={{ desktop: .77, mobile: 1 }}>
                        <Row justifyContent={'center'} responsive>
                            <Column alignItems={'center'}>
                                <Reveal scale={.9}>
                                    <Card direction={'diagonal'} containerCss={'card-container'}>
                                        <ContentBlock background={'g_1'} className={'card'}>

                                            <Row>
                                                <Column>
                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <Image name={'about_1.svg'} height={'100%'} />
                                                    </Row>

                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <span>Immobilie kaufen ohne Anzahlung</span>
                                                    </Row>
                                                </Column>

                                            </Row>

                                        </ContentBlock>

                                        <ContentBlock background={'c_black'} className={'card'}>

                                            <Row>
                                                <Column>
                                                    <p>
                                                        Maximieren Sie Ihre Rendite ohne Anzahlung! Quick Credit findet die ideale Anlageimmobilie in Deutschland und bietet maßgeschneiderte Finanzierungslösungen. Investieren Sie klug für dauerhafte Erträge!
                                                    </p>
                                                </Column>

                                            </Row>

                                        </ContentBlock>
                                    </Card>
                                </Reveal>
                            </Column>

                            <Column alignItems={'center'}>
                                <Reveal scale={.9}>
                                    <Card direction={'diagonal'} containerCss={'card-container'}>
                                        <ContentBlock background={'g_1'} className={'card'}>

                                            <Row>
                                                <Column>
                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <Image name={'about_2.svg'} height={'100%'} />
                                                    </Row>

                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <span>Frische kernsanierte Objekte</span>
                                                    </Row>
                                                </Column>

                                            </Row>

                                        </ContentBlock>

                                        <ContentBlock background={'c_black'} className={'card'}>

                                            <Row>

                                                <p>
                                                    Quick Credit macht's möglich: Investieren Sie in kernsanierte Objekte und sichern Sie sich
                                                    nachhaltige Renditen. Mit modernster Technik und stilvollem Design steigern wir den Wert
                                                    Ihrer Immobilie – effizient, ästhetisch, rentabel.
                                                </p>


                                            </Row>

                                        </ContentBlock>
                                    </Card>
                                </Reveal>
                            </Column>

                            <Column alignItems={'center'}>
                                <Reveal scale={.9}>
                                    <Card direction={'diagonal'} containerCss={'card-container'}>
                                        <ContentBlock background={'g_1'} className={'card'}>

                                            <Row>
                                                <Column>
                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <Image name={'about_3.svg'} height={'100%'} />
                                                    </Row>

                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <span>Sonderverwaltung inklusive</span>
                                                    </Row>
                                                </Column>

                                            </Row>

                                        </ContentBlock>

                                        <ContentBlock background={'c_black'} className={'card'}>

                                            <Row>
                                                <p>
                                                    Die Sonderverwaltung optimiert Ihre Renditeliegenschaften vollumfänglich, von Instandhaltung bis Mieterbetreuung. Unser Ziel: Werterhaltung und sorgenfreie Investition. Vertrauen Sie auf unsere Expertise in der Immobilienverwaltung.
                                                </p>

                                            </Row>

                                        </ContentBlock>
                                    </Card>
                                </Reveal>
                            </Column>

                            <Column alignItems={'center'}>
                                <Reveal scale={.9}>
                                    <Card direction={'diagonal'} containerCss={'card-container'}>
                                        <ContentBlock background={'g_1'} className={'card'}>

                                            <Row>
                                                <Column>
                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <Image name={'about_4.svg'} height={'100%'} />
                                                    </Row>

                                                    <Row alignItems={'center'} justifyContent={'center'}>
                                                        <span>Nach 10 Jahren steuerfreier Verkauf</span>
                                                    </Row>
                                                </Column>

                                            </Row>

                                        </ContentBlock>

                                        <ContentBlock background={'c_black'} className={'card'}>

                                            <Row>
                                                <p>
                                                    In zehn Jahren können Sie Ihre Lieblingsliegenschaft steuerfrei verkaufen oder von langfristigen Mieteinnahmen profitieren. Nutzen Sie diese Chance, um Ihre Immobilieninvestition optimal zu gestalten und Ihre finanziellen Ziele zu erreichen.
                                                </p>
                                            </Row>

                                        </ContentBlock>
                                    </Card>
                                </Reveal>
                                
                            </Column>
                            
                        </Row>
                        <Space size={10} desktop/>
                        
                        <hr />
                        <Reveal>
                            <Button className='application-button' onClick={async () => {
                                 await navigate('/antrag')
                                 scrollTo('', 'start')
                            } } type='primary'>Erhalten Sie jetzt Ihr Angebot</Button>
                        </Reveal>
                        <Space size={10} mobile />
                    </Column>
                </Row>


                <Space size={10} desktop />
            </Container>
            <Image name={'transition_2.svg'} width={'105%'} className={'transition'} />
        </Section>
    )
}