
import { Input } from "../../components/common/Input";
import { Row } from "../../components/common/Row";
import { Column } from "../../components/common/Column";
import { Section } from "../../components/common/Section";
import { Space } from "../../components/common/Space";
import { Container } from "../../components/common/Container";
import { ContentBlock } from "../../components/common/ContentBlock";
import { useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons'

import { Button } from "../../components/common/Button";
import emailjs from '@emailjs/browser'

import './index.scss'
import { Reveal } from "../../components/Reveal";
import { useRef, useState } from "react";

export const ApplicationPage = () => {
	const ref = useRef(null)
	const [isMessageSending, setMessageSending] = useState(false)
	const [applicationSent, setApplicationSent] = useState(false)

	const hiddenInputs = (
		<>
			<input name='application-name' value={useSelector(state => state.data['applicaiton-name']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-number' value={useSelector(state => state.data['application-number']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-email' value={useSelector(state => state.data['application-email']) || ''} readOnly style={{ display: 'none' }} />
			<input name='beruf' value={useSelector(state => state.data['beruf']) || ''} readOnly style={{ display: 'none' }} />
			<input name='investment' value={useSelector(state => state.data['investment']) || ''} readOnly style={{ display: 'none' }} />
		</>
	)

	const handleFormSubmit = (event) => {
		event.preventDefault()
		setApplicationSent(true)
		setMessageSending(true)
		emailjs.sendForm('service_6gwtbdo', 'template_83imf8h', ref.current, 'H8GrK9qEbTz_CaN5t')
			.then((result) => {
				console.log(result.text);


				setMessageSending(false)
			}, (error) => {
				console.log(error.text);
			});
	}


	return (
		<Section background={'c_black'} id={'application'}>
			<Container>
				<Row justifyContent={'center'}>
					<Column flex={{ desktop: .5 }}>

						<Reveal>
							<ContentBlock background={'c_black'} className={'application-form'}>
								{!applicationSent && !isMessageSending &&
									<Column alignItems={'center'} color={'white'}>
										<h3 style={{ textAlign: 'left', fontWeight: '200' }}><strong>Nutzen Sie die Gelegenheit</strong>, in lukrative Anlageimmobilien zu <strong>investieren.</strong> </h3>
										<p style={{ textAlign: 'right', fontWeight: 200, width: '100%', margin: 0, fontSize: '20px' }}>Holen Sie sich jetzt Ihr Angebot!</p>
										<hr />
										<form ref={ref} onSubmit={event => handleFormSubmit(event)}>
											{hiddenInputs}
											<Column>
												<Input type={'text'} label='Name' id='application-name' />
												<Input type='tel' label='Telefonnummer' id='application-number' />
												<Input type='email' label='E-Mail' id='application-email' />
											</Column>

											<Row responsive>

												<Column flex={{ desktop: 0.025, mobile: 0 }} />

											</Row>
											<Space size={2} />
											<Input
												label='Wie ist Ihre derzeitige berufliche Situation?'
												id='beruf'
												type='radio-block'
												columns={2}
												options={['Angestellt', 'Selbständig', 'Investor']} />
											<Space size={3} />
											<Input label='Was können Sie monatlich investieren?' id='investment' type='radio-block' columns={2} options={['100fr. bis 500fr.', '500fr. bis 1000fr.', '1000fr. und mehr']} />
											<Space size={3} />
											<hr />
											<Button bType={'submit'} icon={'msg.svg'} type='primary'>Absenden</Button>
										</form>
									</Column>
								}

								{applicationSent &&
									<>
										{isMessageSending && <Row justifyContent={'center'}><LoadingOutlined style={{ fontSize: '82px', color: 'white' }} /></Row>}
										{!isMessageSending &&
											<>
												<Row justifyContent={'center'} color={'white'}>
													<Column>
														<h2 style={{ textAlign: 'center' }}><strong>Vielen Dank für Ihre Anfrage.</strong></h2>
														<p style={{ textAlign: 'center' }}>Ein Immobilienexperte wird sich innerhalb der nächsten 24 Stunden bei Ihnen melden.</p>
													</Column>


												</Row>
											</>
										}
									</>


								}

							</ContentBlock>
						</Reveal>




					</Column>
				</Row>

			</Container>
		</Section>
	);
}