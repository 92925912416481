export const imageNames = [
    'about_1.svg',
    'about_2.svg',
    'about_3.svg',
    'about_4.svg',
    'checkbox.svg',
    'citylandscape.svg',
    'divider.svg',
    'down-arrow.svg',
    'edit.svg',
    'facebook.svg',
    'home_black.svg',
    'info_1.svg',
    'info_2.svg',
    'info_3.svg',
    'info_4.svg',
    'info_5.svg',
    'instagram.svg',
    'line.svg',
    'logo.svg',
    'menu.svg',
    'msg.svg',
    'notfound.svg',
    'phone.svg',
    'prognose_desktop.svg',
    'prognose_mobile.svg',
    'roadmap_1.svg',
    'roadmap_2.svg',
    'roadmap_3.svg',
    'roadmap_4.svg',
    'roadmap_5.svg',
    'roadmap_6.svg',
    'transition_1.svg',
    'transition_2.svg',
    'transition_3.svg',
    'transition_3.svg',
    'transition_3.svg',
]