
import { useNavigate } from "react-router-dom"
import { scrollTo } from "../../utils/helpers"
import { Row } from "../../components/common/Row"
import { Column } from "../../components/common/Column"

import { Container } from "../../components/common/Container"
import { Image } from "../../components/common/Image"
import { Link } from "../../components/common/Link"
import { Space } from "../../components/common/Space"


export const Footer = () => {
    const navigate = useNavigate()

    return (
        <footer>
            <Container>

                <Row>
                    <Column alignItems='flex-start'>
                        <Image
                            name='logo.svg'
                            alt={'logo'}
                            id='footer-logo'
                            onClick={async () => {
                                await navigate('/')
                                scrollTo('', 'start')
                            }}
                        />
                    </Column>

                </Row>
                <Row responsive>
                    <Column flex={{ desktop: 5 }}>

                        <Row justifyContent={'flex-end'}>
                            <Column flex={{ desktop: .15 }}>
                                <span><strong>Navigation</strong></span>
                                <ul>
                                    <li onClick={async () => {

                                        await navigate('')
                                        scrollTo('about', 'start')
                                    }} className="link">Über Uns</li>
                                    <li onClick={async () => {

                                        await navigate('')
                                        scrollTo('contact', 'start')
                                    }} className="link">Kontakt</li>
                                    <li><Link href='/faq'>FAQ</Link></li>
                                    <li><Link href='/impressum'>Impressum</Link></li>
                                    <li><Link href='/datenschutz'>Datenschutz</Link></li>
                                </ul>
                            </Column>
                        </Row>

                    </Column>
                    <Column>
                        <Space size={2} mobile/>
                        <span><strong>Social</strong></span>
                        <Space size={1} />
                        <Row alignItems={'flex-start'} className={'social-icons'}>
                            <Column flex={{ desktop: .2, mobile: 1 }}>
                                <a className="link" href='/facebook' aria-label="facebook">
                                    <Image name={'facebook.svg'} width={'30px'} height={'30px'} />
                                </a>
                            </Column>

                            <Column flex={{ desktop: .2, mobile: 1 }}>
                                <a className="link" href='/instagram' aria-label="instagram">
                                    <Image name={'instagram.svg'} width={'30px'} height={'30px'} />
                                </a>
                            </Column>
                        </Row>
                    </Column>
                </Row>
                <hr />
                <Row responsive>
                    <Column>
                        <div style={{display: 'flex'}}>
                            <Image id={'contact-icon'} name={'phone.svg'} width={'20px'} height={'20px'} />
                            <span> +41 76 442 89 25</span>
                        </div>
                           
                        

                    </Column>
                    <Space size={2} mobile />
                    <Column alignItems={'flex-end'} justifyContent={'center'} flex={{ desktop: 3, mobile: 1 }}>
                        <span>© Quick Credit Schweiz 2024</span>
                    </Column>
                </Row>


            </Container>
        </footer>
    )
}