import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { HomePage } from './pages/HomePage'
import { LoginPage } from './pages/LoginPage'
import { ContactPage } from './pages/ContactPage'
import { LandingPage } from './pages/LandingPage'
import { DatenschutzPage } from './pages/DatenschutzPage'
import { ImpressumPage } from './pages/ImpressumPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { ApplicationPage } from './pages/ApplicationPage'
import { Loading } from './sections/Loading';
import { Footer } from './sections/Footer';
import { FAQ } from './sections/FAQ';


import { Navbar } from './sections/Navbar'

import './App.scss'
import { useDispatch, useSelector } from 'react-redux';
import { preloadImages } from './utils/preloadImages';


const App = () => {	
	const [isLoading, setIsLoading] = useState(true)	

	const dispatch = useDispatch()
	const isPreloaded = useSelector(state => state.preload.isPreloaded)
	const isPreloading = useSelector(state => state.preload.isPreloading)
	const error = useSelector(state => state.preload.error)

	useEffect(() => {
		dispatch(preloadImages())
	}, [dispatch])

	/*useEffect(() => {
		const loadAssets = async () => {
			try {
				await preloadAssets()
				setIsLoading(false)

			} catch (error) {
				console.error('Error loading assets:', error)
				setIsLoading(false)
			}
		}
		loadAssets()
	}, [])

	if (isLoading) {
		return <Loading />
	}*/

	if (isPreloading) {
        return <Loading />; // Show a loading indicator while images are preloading
    }

    if (error) {
        return <div>Error loading images: {error.message}</div>; // Handle loading errors
    }
	
	return (
		<Router>			
			<div className="App">
				
				<Navbar items={[
					{
						items: [							
							{
								title: 'Über Uns',
								type: 'pointer',
								url: '/',
								section: 'about'
							},
							{
								title: 'Kontakt',
								type: 'pointer',
								url: '/',
								section: 'contact'
							},
							{
								title: 'FAQ',
								type: 'pointer',
								url: '/faq',
								section: ''
							},
						]
					},	
					{
						items: [							
							{
								title: 'Jetzt Anfragen',
								type: 'action-link',
								url: '/antrag',	
								buttonType: 'secondary',
								icon: 'home.svg',
								id:'antrag-button',
							},
						]
					},					
				]} />
				
					<Routes>
						<Route exact path="/" element={<HomePage />} />
						<Route path="/login" element={<LoginPage />} />
						<Route path="/contact" element={<ContactPage />} />
						<Route path="/landing" element={<LandingPage />} />
						<Route path="/impressum" element={<ImpressumPage />} />
						<Route path="/datenschutz" element={<DatenschutzPage />} />							
						<Route path="/antrag" element={<ApplicationPage />} />			
						<Route path="/faq" element={<FAQ />} />										
						{/* Add more routes as needed */}
						{/* 404 route */}
						<Route path='*' element={<NotFoundPage />} />
					</Routes>
				<div id='footer-container'>
					<Footer />
				</div>


				{ /*
			<Navbar />
			<Header />
			<About />
			<Contact />
			<Footer />
		*/ }
			</div>
		</Router>

	)
}

export default App
