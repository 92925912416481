import Particles from "react-tsparticles"
import { useMemo, useCallback } from "react"
import { loadSlim } from 'tsparticles-slim'

import './index.scss'

export const TSParticles = () => {
    const particlesInit = useCallback(async engine => {
        
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        
    }, []);

    return  (
        <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        canvasClassName={"canvas"}             
        options={{
            
            fpsLimit: 120,
            fullScreen: false,
            particles: {
                color: {
                    value: "#FFFFFF",
                },     
                links: {
                    color: "#FFFFFF",
                    distance: 100,
                    enable: false,
                    opacity: .3,
                    width: 1,
                },           
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: true,
                    speed: .5,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 500,
                    },
                    value: 20,
                },                
                opacity: {
                    value: 0.3,
                },
                shape: {
                    type: "square",
                },
                size: {
                    value: { min: 1, max: 10 },
                },
            },
            detectRetina: true,
        }}
    />
    )
}