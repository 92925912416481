import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { Row } from "../../components/common/Row"
import { Image } from "../../components/common/Image"
import { Reveal } from "../../components/Reveal"

import Video from '../../assets/videos/header.mp4'




export const Header = () => {
    return (
        <div id="header">
            <Container>
                <Row responsive>
                    <Column alignItems='flex-start' justifyContent='center'>
                        <Reveal width="fit-content" y={0} x={-50}><h1>Immobilien Investment</h1></Reveal>
                        <Reveal width="fit-content"  y={0} x={50}><span className="from-price">ab <strong>149.-</strong> mntl.</span></Reveal>
                        <Reveal width="fit-content" ><span className="slogan">Jetzt oder Nie!</span></Reveal>
                    </Column>
                </Row>
            </Container>

            <video autoPlay preload='auto' playsInline controls={false} loop muted className='header-video' src={Video} type='video/mp4' />            

            <Image name={'citylandscape.svg'} width={'95%'} className={'header-image'} />


            <div className="header-background" />
        </div>
    )
}