import { useState } from "react"

import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { Row } from "../../components/common/Row"
import { Section } from "../../components/common/Section"
import { Image } from "../../components/common/Image"
import { Space } from "../../components/common/Space"

import './index.scss'
import { Button } from "../../components/common/Button"
import { Reveal } from "../../components/Reveal"
import { ContentBlock } from "../../components/common/ContentBlock"
import { scrollTo } from "../../utils/helpers"
import { useNavigate } from "react-router-dom"

export const Calculator = () => {
    const navigate = useNavigate()

    const formatter = (num) => {
        if (num > 999999) {
            return Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M';
        } else if (num > 999) {
            return Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k';
        } else {
            return Math.sign(num) * Math.abs(num)
        }
    }

    const [salary, setSalary] = useState(4500)
    const [focus, setFocus] = useState(false)

    const updateSalary = ({ value }) => {
        if (value.length < 8)
            setSalary(value)
    }

    const getSalary = () => {
        return salary.toString().replace(`'`, '').replace(`'`, '')
    }

    const getPropertyRange = () => {
        if (getPropertyLowerValue()) {
            return getPropertyLowerValue() + " - " + getPropertyHigherValue()
        } else {
            return getPropertyHigherValue()
        }
    }
    const getPropertyLowerValue = () => Math.floor(getLoanLowerValue() / 100000);
    const getPropertyHigherValue = () => Math.floor(getLoanHigherValue() / 100000);

    const getLoanRange = () => formatter(getLoanLowerValue()) + " - " + formatter(getLoanHigherValue())
    const getLoanLowerValue = () => 60 * getSalary() - 90000;
    const getLoanHigherValue = () => Math.ceil(Math.round(125 * getSalary() - 312500) / 100000) * 100000;


    const getProfit = () => {
        let profit = []

        if (getPropertyLowerValue() && getPropertyHigherValue) {
            profit[0] = getPropertyLowerValue() * 47000
            profit[1] = getPropertyHigherValue() * 47000
        }

        return profit
    }

    return (
        <Section id={'calculator'} background={'c_black'}>

            <Container>
                <Row justifyContent={'center'}>
                    <Column color={'white'} alignItems={'center'}>
                        <Image name={'divider.svg'} className={'divider'} width={'700px'} />
                        <Space size={5} desktop />
                        <Space size={5} mobile />
                        <h2><strong>Probieren</strong> Sie unsere <strong>Simulation</strong> aus</h2>
                        <Space size={3} desktop />
                        <Space size={2} mobile />

                        <Reveal><ContentBlock className={'calc-content'}>
                            <Row responsive justifyContent={'center'}>
                                <Column>

                                    <Row>
                                        <span>
                                            <label htmlFor="amount-input"><strong>Monatliches Einkommen: </strong></label>
                                            <b className="salary-input">
                                                <input
                                                    id="amount-input"
                                                    value={salary.toString().replace("'", '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") + (focus ? '' : '.-')}
                                                    onFocus={() => {
                                                        setSalary('')
                                                        setFocus(true)
                                                    }}
                                                    onBlur={(event) => {
                                                        setFocus(false)
                                                        let amount = event.target.value.toString().replace(`'`, '').replace(`'`, '')
                                                        if (parseInt(amount) < 4500 || !parseInt(amount)) {
                                                            setSalary(4500)
                                                        }

                                                    }}
                                                    onChange={(event) => updateSalary(event.target)}
                                                    inputMode="numeric"
                                                />
                                                {(!focus && getSalary() == 4500) && <Image name={'edit.svg'} className={'edit-icon'} width={'30px'} />}    
                                                
                                            </b>
                                            
                                            
                                        </span>
                                        
                                    </Row>

                                    <Row><span><strong>Kreditbetrag: </strong><b>
                                        {getSalary() >= 4500 ? getLoanRange() : '-'}
                                    </b></span></Row>
                                    <Row><span><strong>Renditeliegenschaften: </strong>
                                        <b>
                                            <Row alignItems={'center'}>

                                                <Image height='30px' name={'home.svg'} />
                                                <div style={{ marginLeft: '20px' }}>
                                                    {getSalary() >= 4500 ? getPropertyRange() : '-'}
                                                </div>

                                            </Row>

                                        </b>

                                    </span></Row>


                                </Column>
                                <Space size={3} mobile />
                                <Column alignItems={'center'}>
                                    <span><strong>Möglicher Gewinn in 10 Jahren:</strong></span>
                                    <hr />

                                    {getSalary() >= 4500 &&
                                        <b className="profit">
                                            {formatter(getProfit()[1])}
                                        </b>

                                    }


                                </Column>

                            </Row>
                        </ContentBlock>
                        </Reveal>
                        <Space size={10} desktop />
                        <Space size={7} mobile />

                        <Row justifyContent={'center'}>
                            <Column flex={{ desktop: .6, mobile: 1 }}>
                                <Reveal width="fit-content"><p>Unsere Berechnungen zur Wertsteigerung von Immobilien in Deutschland basieren auf fundierten Prognosen und Szenarien.</p></Reveal>
                                <Reveal width="fit-content"><p><strong>*Diese Berechnungen garantieren keine tatsächliche Leistung oder Rendite.</strong> Die Marktbedingungen können sich ändern und es besteht immer das Risiko, dass die tatsächlichen Ergebnisse von den prognostizierten abweichen.</p></Reveal>

                                <Space size={5} />
                                <Space size={2} mobile />

                                <Row justifyContent={'center'}>
                                    <Reveal>
                                        <Button onClick={async () => {
                                            await navigate('/antrag')
                                            scrollTo('', 'start')
                                        }} className="application-button" type={'primary'}>Erhalten Sie jetzt Ihr Angebot</Button>
                                    </Reveal>

                                </Row>
                            </Column>
                        </Row>
                    </Column>
                </Row>

            </Container>
            <Image name={'line.svg'} width={'70%'} className={'calc-background'} />
        </Section>
    )
}