import { Column } from '../../components/common/Column';
import { Container } from '../../components/common/Container';
import { Section } from '../../components/common/Section'

export const DatenschutzPage = () => {
    return (
        <Section background={'c_black'}>
            <Container>
                <Column color={'white'}>
                    <h2>
                        <strong>Datenschutzerklärung</strong>
                    </h2>
                    <h3>1. Einleitung</h3>
                    <p>
                        Wir, Quick Credit Schweiz GmbH, nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Diese Datenschutzerklärung informiert Sie darüber, wie wir Ihre Daten im Rahmen unserer Dienstleistungen im Bereich der Immobilienvermittlung erheben, verarbeiten und weitergeben. Sie basiert auf den Anforderungen des Schweizer Datenschutzgesetzes (DSG).
                    </p>
                    <h3>2. Verantwortliche Stelle</h3>
                    <span>Quick Credit Schweiz GmbH</span>
                    <span>Baarermatte 1, 6340 Baar</span>
                    <span>info@quick-credit.ch</span>
                    <h3>3. Erhebung und Verarbeitung personenbezogener Daten</h3>
                    <p>Wir erheben und verarbeiten personenbezogene Daten, die Sie uns im Rahmen einer Anfrage übermitteln. Dazu gehören:</p>
                    <ul>
                        <li>Vorname, Nachname</li>
                        <li>Adresse</li>
                        <li>E-Mail-Adresse</li>
                        <li>Finanzielle Informationen (z.B. Einkommensnachweise, Bonitätsauskünfte)</li>
                    </ul>
                    <p>Diese Daten werden benötigt, um Sie zu kontaktieren und Ihnen unsere Dienstleistungen anzubieten.</p>
                    <h3>4. Zweck der Datenverarbeitung</h3>
                    <p>Die erhobenen Daten werden zu folgenden Zwecken verwendet:</p>
                    <ul>
                        <li>Kontaktaufnahme und Kommunikation im Rahmen der Immobilienvermittlung</li>
                        <li>Bewertung Ihrer Bonität und Finanzierungsmöglichkeiten</li>
                        <li>Weitergabe an Finanzinstitute zur Prüfung der Finanzierung</li>
                    </ul>
                    <h3>5. Weitergabe personenbezogener Daten</h3>
                    <p>Ihre personenbezogenen Daten können an folgende Dritte weitergegeben werden:</p>
                    <ul>
                        <li>Deutsche Banken zur Bonitätsprüfung</li>
                        <li>Schweizer Finanzinstitute zur Prüfung der Finanzierung</li>                   
                    </ul>
                    <h3>6. Datensicherheit</h3>
                    <p>Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten vor unbefugtem Zugriff, Verlust oder Manipulation zu schützen. Unsere Sicherheitsmaßnahmen werden regelmäßig überprüft und entsprechend dem technologischen Fortschritt angepasst.</p>
                    <h3>7. Aufbewahrungsdauer</h3>
                    <p>Ihre personenbezogenen Daten werden nur so lange aufbewahrt, wie dies für die Erfüllung der genannten Zwecke notwendig ist oder wie es gesetzliche Aufbewahrungsfristen vorsehen.</p>
                    <h3>8. Rechte der betroffenen Personen</h3>
                    <p>Sie haben das Recht:</p>
                    <ul>
                        <li>Auskunft über die von uns verarbeiteten personenbezogenen Daten zu erhalten</li>
                        <li>Berichtigung unrichtiger Daten zu verlangen</li>
                        <li>Löschung Ihrer Daten zu fordern, sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen</li>
                        <li>Die Einschränkung der Datenverarbeitung zu verlangen</li>
                        <li>Der Datenverarbeitung zu widersprechen</li>
                        <li>Datenübertragbarkeit zu verlangen</li>
                    </ul>
                    <p>Zur Ausübung Ihrer Rechte können Sie uns unter den oben genannten Kontaktinformationen erreichen.</p>
                    <h3>9. Änderungen der Datenschutzerklärung</h3>
                    <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf anzupassen. Die aktuelle Fassung finden Sie jederzeit auf unserer Webseite.</p>
                    <h3>10. Kontakt</h3>
                    <p>Bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten oder zum Datenschutz können Sie sich jederzeit an uns wenden:</p>
                    <span>+41 76 442 89 25</span>
                    <span>info@quick-credit.ch</span>
                    <p>Diese Datenschutzerklärung wurde zuletzt am 01.05.2024 aktualisiert.</p>
                    <br/>
                    <p><strong>Bitte beachten Sie, dass dies ein allgemeines Beispiel ist und möglicherweise nicht alle rechtlichen Anforderungen für Ihre spezifische Situation abdeckt. Es wird dringend empfohlen, diese Datenschutzerklärung von einem Fachanwalt für Datenschutzrecht prüfen zu lassen.</strong></p>
                </Column>

            </Container>

        </Section>
    );
}

