import { useEffect, useInsertionEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion"


export const Reveal = ({children, y = 50, x = 0, scale, delay = 0.25, width = "100%"}) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    const mainControls = useAnimation()    

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible")
        }
    }, [isInView])

    return (
        
            <motion.div
                variants={{
                    hidden: {opacity: 0, y: y, x: x, scale: scale},
                    visible: {opacity: 1, y: 0, x: 0, scale: 1},
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 1, delay: delay}}
                ref={ref} style={{ 
                    position: "relative", 
                    width,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {children}
            </motion.div>
        
    )
}