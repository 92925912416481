import { Container } from "../../components/common/Container";
import { Section } from "../../components/common/Section";
import { Row } from "../../components/common/Row";
import { Image } from "../../components/common/Image";
import { Column } from "../../components/common/Column";

import './index.scss'

export const NotFoundPage = () => {
    return (
        <Section background={'c_black'}>
            <Container className={'nofound-container'}>
                {/* NotFoundPage page content */}
                <Row color='white' justifyContent={'center'}>
                    <Column alignItems={'center'}>
                        <Image width='100px' name='notfound.svg' />
                        <h1>
                            404 Page not found
                        </h1>
                    </Column>

                </Row>

            </Container>
        </Section>
    );
}