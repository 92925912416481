import { Container } from "../../components/common/Container"
import { Section } from "../../components/common/Section"
import { Row } from "../../components/common/Row"
import { Column } from "../../components/common/Column"
import { Image } from "../../components/common/Image"
import { Space } from "../../components/common/Space"
import { Reveal } from "../../components/Reveal"

import './index.scss'
import { ContentBlock } from "../../components/common/ContentBlock"

export const Prognose = () => {
    return (
        <Section id='prognose' background={'c_black'}>
            <Container>
                
                <Row>
                    <Column color={'white'} alignItems={'center'}>
                        <Space size={3} desktop />
                        <Reveal><h2>
                            Zukunft <strong>Prognose</strong>
                        </h2></Reveal>

                        <Space size={5} />
                        <Reveal y={0} scale={.7}>
                            <Row justifyContent={'center'}>
                                <Image name={'prognose_desktop.svg'} width={'100%'} className={'image-desktop'} alt={'Zukunft Prognose'}/>
                                <Image name={'prognose_mobile.svg'} width={'100%'} className={'image-mobile'} alt={'Zukunft Prognose'}/>
                            </Row>
                                
                            
                        </Reveal>
                        <Space size={5} />

                        <Reveal><p>Die Berechnung erfolgt ohne Berücksichtigung einer Wertsteigerung.</p></Reveal>
                    </Column>
                </Row>
            </Container>
        </Section>
    )
}