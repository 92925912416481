// Redux store configuration// store.js or in your main file (index.js or index.jsx)
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import {thunk} from 'redux-thunk';
import {preloadReducer} from './reducers/preloadReducer'; // Adjust the import path 
import { dataReducer } from './reducers/dataReducer';

const rootReducer = combineReducers({
    preload: preloadReducer,
    data: dataReducer,
    // Add other reducers here
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export default store;