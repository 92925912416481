import { useState } from "react"

import { Column } from "../../components/common/Column"
import { Accordion } from "../../components/common/Accordion"
import { Row } from "../../components/common/Row"
import { Section } from "../../components/common/Section"
import { Container } from "../../components/common/Container"

import './index.scss'
import { Reveal } from "../../components/Reveal"

export const FAQ = () => {
    const [expanded, setExpanded] = useState(false)

    return (
        <Section id='faq' background={'c_black'}>
            <Container>
                <Column color={'white'}>
                    <Reveal><h2><strong>FAQ</strong></h2></Reveal>
                    <Reveal><p>Häufig gestellte Fragen</p></Reveal>
                    <Reveal><hr/></Reveal>
                    <Reveal>
                    <Row responsive alignItems='flex-start'>
                        <Column>
                            <Accordion justifyHeading={'space-between'} heading='Wie lange dauert die Bearbeitung meiner Daten?' i={0} expanded={expanded} setExpanded={setExpanded}>
                                <p>Unser Team bearbeitet deine Anfrage umgehend. Wir melden uns innert 48h bei dir mit einer Einschätzung zu deiner Situation.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Wie sicher ist meine Anlage?' i={1} expanded={expanded} setExpanded={setExpanded}>
                                <p>Immobilienanlagen gehören im 21. Jahrhundert zu den gefragtesten und sichersten Investments, da sie weder an Währungen noch an Aktienmärkte gebunden sind. Bei einem Beratungsgespräch besprechen wir alle wichtigen Punkte mit dir.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Wer ist mein fester Ansprechpartner?' i={2} expanded={expanded} setExpanded={setExpanded}>
                                <p>Du bekommst einen persönlichen Ansprechpartner der alle deine Anliegen und Fragen rund um dein Investment beantwortet und dich persönlich betreut.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Wer kümmert sich um die Immobilie?' i={3} expanded={expanded} setExpanded={setExpanded}>
                                <p>Um die Bewirtschaftung der Immobilie kümmert sich die von dir ausgewählte Verwaltung.</p>
                            </Accordion>


                        </Column>
                        <Column flex={{ desktop: 0.015 }} />
                        <Column>
                            <Accordion justifyHeading={'space-between'} heading='Was passiert bei Mietausfall?' i={4} expanded={expanded} setExpanded={setExpanded}>
                                <p>Bei unseren Immobilien handelt es sich um sorgfältig ausgewählte Objekte, die ein generell geringes Risiko aufweisen, leer zu stehen. Jedoch sichern wir dich dennoch mit unserem etablierten Konzept gegen Mietausfälle ab. Dies unabhängig davon ob deine Wohnung gerade vermietet ist oder nicht.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Was passiert, wenn ich arbeitslos werde und die Kosten nicht tragen kann?' i={5} expanded={expanded} setExpanded={setExpanded}>
                                <p>Dein Mieter bezahlt seine Miete unabhängig von deiner Arbeits- bzw. Beschäftigungssituation. Zusätzlich bieten unsere Partner dir einen maximalen Versicherungsschutz.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Welche steuerlichen Vorteile habe ich?' i={6} expanded={expanded} setExpanded={setExpanded}>
                                <p>Nach 10 Jahren kannst du deine Immobilie 100% steuerfrei verkaufen. Das Steuergesetz AFA (Absetzung für Abnutzung) ermöglicht es dir bei vermieteten Immobilien jährliche Rückzahlungen zu erlangen.</p>
                            </Accordion>
                        </Column>
                    </Row>
                    </Reveal>
                </Column>
            </Container>
        </Section>
    )
}