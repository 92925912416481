import ReactFlipCard from "reactjs-flip-card"

export const Card = ({ flipByProp, children, containerCss, backCss, flipCardCss, direction }) => {
    return (
        <ReactFlipCard
            frontComponent={children[0]}
            backComponent={children[1]}
            containerCss={containerCss}
            flipCardCss={flipCardCss}
            backCss={backCss}
            direction={direction}
            flipByProp={flipByProp}
        />        
    )
}