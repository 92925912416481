import { Column } from "../../components/common/Column"
import { Section } from "../../components/common/Section"
import { Container } from "../../components/common/Container"
import { Row } from "../../components/common/Row"
import { Button } from "../../components/common/Button"
import { ContentBlock } from "../../components/common/ContentBlock"
import { Space } from "../../components/common/Space"

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { scrollTo } from "../../utils/helpers"


// import required modules
import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';

import './index.scss'
import { Image } from "../../components/common/Image"
import { Reveal } from "../../components/Reveal"
import { useNavigate } from "react-router-dom"

export const Info = () => {
    const navigate = useNavigate()

    return (
        <Section id={'info'}>
            <Image name={'transition_3.svg'} width={'110%'} className={'transition'} />
            <Container className={'info-section'}>
                <Space size={20} desktop /> <Space size={5} mobile />
                <Row justifyContent={'center'}>
                    <Reveal><h2>Alles rund um Ihre <strong>Immobilieninvestitionen</strong></h2></Reveal>
                </Row>
                <Space size={10} desktop /> <Space size={5} mobile />
                <Row responsive>
                    <Column alignItems={'flex-start'}>
                        <Reveal y={0} x={-75}>
                            <div>
                                <Swiper
                                    effect={'cube'}
                                    grabCursor={true}
                                    cubeEffect={{
                                        shadow: true,
                                        slideShadows: true,
                                        shadowOffset: 20,
                                        shadowScale: 0.94,
                                    }}
                                    pagination={true}
                                    modules={[EffectCube, Pagination, Autoplay]}
                                    className="infoSwiper"
                                    loop={true}
                                    allowTouchMove={false}
                                    draggable={false}
                                    style={{
                                        '--swiper-navigation-color': '#fff',
                                        '--swiper-pagination-color': '#fff',
                                    }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: true,
                                    }}
                                >
                                    <SwiperSlide>
                                        <ContentBlock background={'g_1'} className={'icon-container'}>
                                            <Image name={'info_2.svg'} height={'100%'} />
                                        </ContentBlock>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ContentBlock background={'g_1'} className={'icon-container'}>
                                            <Image name={'info_1.svg'} height={'100%'} />
                                        </ContentBlock>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ContentBlock background={'g_1'} className={'icon-container'}>
                                            <Image name={'info_3.svg'} height={'100%'} />
                                        </ContentBlock>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ContentBlock background={'g_1'} className={'icon-container'}>
                                            <Image name={'info_4.svg'} height={'100%'} />
                                        </ContentBlock>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ContentBlock background={'g_1'} className={'icon-container'}>
                                            <Image name={'info_5.svg'} height={'100%'} />
                                        </ContentBlock>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </Reveal>
                    </Column>

                    <Column alignItems={'flex-start'} flex={{ desktop: 4, mobile: 1 }}>
                        <Reveal y={0} x={75}>
                            <div>
                                <Swiper
                                    className="infoSwiperText"
                                    modules={[Navigation, Autoplay]}
                                    loop={true}
                                    spaceBetween={100}
                                    allowTouchMove={false}
                                    draggable={false}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: true,
                                    }}
                                >
                                    <SwiperSlide>
                                        <span><strong>Beratung</strong></span>
                                        <Space size={2} />
                                        <p>Umfassende Beratung & persönliche Betreuung beim Immobilieninvestment.</p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <span><strong>Finanzierung</strong></span>
                                        <Space size={2} />
                                        <p>Auf Wunsch bieten wir individuelle Finanzierungskonzepte durch unabhängige Finanzierungsberater bei regionalen Banken an.</p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <span><strong>Hausverwaltung</strong></span>
                                        <Space size={2} />
                                        <p>Professionelles Property Management (interne WEG- & SE-Verwaltung).</p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <span><strong>Mietverwaltung</strong></span>
                                        <Space size={2} />
                                        <p>Mietverwaltungssystem für Mietsicherheit & Mietvertragsoptimierung.</p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <span><strong>Überwachung</strong></span>
                                        <Space size={2} />
                                        <p>Überwachung notwendiger Renovierungs- & Modernisierungsmaßnahmen.</p>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </Reveal>
                    </Column>
                    <Column alignItems={'center'} justifyContent={'center'}>
                        <button className='swiper-button-next next-button' aria-label="next" onClick={() => {
                            document.querySelector(`.infoSwiperText`).swiper.slideNext()
                            document.querySelector(`.infoSwiper`).swiper.slideNext()

                        }}></button>
                    </Column>
                </Row>
                <Row justifyContent={'center'}>
                    <Column flex={{ desktop: .6 }}>
                        <Space size={10} />
                        <Row justifyContent={'center'}>
                            <Button onClick={async () => {
                                await navigate('/antrag')
                                scrollTo('', 'start')
                            }} className="application-button" type='primary'>Erhalten Sie jetzt Ihr Angebot</Button>
                        </Row>

                    </Column>
                </Row>
                <Space size={30} desktop /> <Space size={5} mobile />




            </Container>
            <Image name={'line.svg'} width={'70%'} className={'info-line'} />
            <Image name={'transition_1.svg'} width={'105%'} className={'transition-bottom'} />
        </Section>
    )
}