import { Row } from "../../components/common/Row"
import { Column } from "../../components/common/Column"
import { Input } from "../../components/common/Input"
import { Section } from "../../components/common/Section"
import { Button } from "../../components/common/Button"
import { Space } from "../../components/common/Space"
import { Container } from "../../components/common/Container"
import { ContentBlock } from "../../components/common/ContentBlock"
import { Image } from "../../components/common/Image"
import { useSelector } from "react-redux"

import { LoadingOutlined } from '@ant-design/icons'
import './index.scss'
import { Reveal } from "../../components/Reveal"

import emailjs from '@emailjs/browser'
import { useRef, useState } from "react";

export const Contact = () => {
    const ref = useRef(null)
    const [isMessageSending, setMessageSending] = useState(false)
    const [applicationSent, setApplicationSent] = useState(false)

    const hiddenInputs = (
        <>
            <input name='contact-name' value={useSelector(state => state.data['contact-name']) || ''} readOnly style={{ display: 'none' }} />
            <input name='contact-lastname' value={useSelector(state => state.data['contact-lastname']) || ''} readOnly style={{ display: 'none' }} />

            <input name='contact-number' value={useSelector(state => state.data['contact-number']) || ''} readOnly style={{ display: 'none' }} />
            <input name='contact-email' value={useSelector(state => state.data['contact-email']) || ''} readOnly style={{ display: 'none' }} />
            <input name='contact-message' value={useSelector(state => state.data['contact-message']) || ''} readOnly style={{ display: 'none' }} />
        </>
    )

    const handleFormSubmit = (event) => {
        event.preventDefault()
        setApplicationSent(true)
        setMessageSending(true)
        emailjs.sendForm('service_6gwtbdo', 'template_83imf8h', ref.current, 'H8GrK9qEbTz_CaN5t')
            .then((result) => {
                console.log(result.text);


                setMessageSending(false)
            }, (error) => {
                console.log(error.text);
            });
    }


    return (
        <Section id='contact' background={'g_3'}>
            <Container>
                <Row justifyContent={'center'}>

                    <Column justifyContent={'center'} flex={{ desktop: 0.5 }} alignItems={'center'} color={'white'}>
                        <Reveal><h2 style={{textAlign: 'center'}}><strong>Haben</strong>Sie eine <strong>Frage?</strong></h2></Reveal>
                        <Reveal><p style={{ textAlign: 'center' }}>Wir sind für Sie da, wenn Sie Fragen haben oder Hilfe benötigen. Bitte zögern Sie nicht uns zu kontaktieren! </p></Reveal>
                        <Space size={3} />

                        <Reveal>
                            <form ref={ref} onSubmit={event => handleFormSubmit(event)}>
                                {!applicationSent && !isMessageSending &&
                                    <>
                                        <Row responsive>
                                            <Input type='text' label='Name' id='contact-name' />
                                            <Column flex={{ desktop: 0.025 }} />
                                            <Input type='text' label='Nachname' id='contact-lastname' />
                                        </Row>

                                        <Row responsive>
                                            <Input type='tel' label='Telefonnummer' id='contact-number' />
                                            <Column flex={{ desktop: 0.025, mobile: 0 }} />
                                            <Input type='email' label='E-Mail' id='contact-email' />
                                        </Row>

                                        <Row>
                                            <Input type='textarea' label='Nachricht' id='contact-message' />
                                        </Row>
                                        <Row justifyContent={'center'}>
                                            <Button icon={'msg.svg'} type={'primary'}>Absenden</Button>
                                        </Row>
                                    </>
                                }

                                {applicationSent &&
                                    <>
                                        {isMessageSending && <Row justifyContent={'center'}><LoadingOutlined style={{ fontSize: '82px' }} /></Row>}
                                        {!isMessageSending &&
                                            <>
                                                <Row justifyContent={'center'} color={'black'}>
                                                    <Column>
                                                        <h2 style={{ textAlign: 'center', textShadow: 'none' }}><strong>Vielen Dank</strong> für Ihre Nachricht.</h2>
                                                        <p style={{ textAlign: 'center' }}>Ein Immobilienexperte wird sich innerhalb der nächsten 24 Stunden bei Ihnen melden.</p>
                                                    </Column>


                                                </Row>
                                            </>
                                        }
                                    </>


                                }


                            </form>
                        </Reveal>




                    </Column>
                </Row>
            </Container>
            <Image name={'citylandscape.svg'} width={'102%'} className={'contact-image'} />
        </Section>
    )
}