import { Column } from "../../components/common/Column"
import { Section } from "../../components/common/Section"
import { Container } from "../../components/common/Container"
import { Row } from "../../components/common/Row"
import { ContentBlock } from "../../components/common/ContentBlock"
import { Space } from "../../components/common/Space"
import { Image } from "../../components/common/Image"

import './index.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'


import { Parallax, Pagination, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Reveal } from "../../components/Reveal"

export const Roadmap = () => {
    return (
        <Section id={'roadmap'} background={'g_3'}>
            <Row justifyContent={'center'}>
                <Column alignItems={'center'} color='white'>                    
                    <Image name={'divider.svg'} className={'divider'} width={'700px'} />
                    <Space size={17} desktop />
                    <Space size={15} mobile />
                    <Reveal><h2><strong>So funktioniert</strong> es</h2></Reveal>
                    <Space size={10} desktop/>
                </Column>
                
            </Row>
            <Reveal>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                speed={600}
                parallax={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Parallax, Pagination, Navigation]}
                className="mySwiper"                
            >
                <div
                    slot="container-start"
                    className="parallax-bg"

                    data-swiper-parallax="-23%"
                ></div>
                <SwiperSlide>
                    <Container>
                        <Row responsive>
                            <Column>
                                <div className="title" data-swiper-parallax="-500">
                                    1. Auswahl der Immobilien
                                </div>
                                <Space size={3} />
                                <div className="text" data-swiper-parallax="-300">
                                    <p>
                                        Unser Team von Experten identifiziert sorgfältig erstklassige Immobilienchancen in Deutschland.
                                        Unsere Experten finden speziell für Sie die besten Immobilien in den besten Gegenden Deutschlands.
                                        Wir konzentrieren uns auf Immobilien mit starken Mieteinnahmen und Wachstumspotenzial, um sicherzustellen,
                                        dass jede Investition attraktive Renditen bietet.
                                    </p>
                                </div>
                            </Column>

                            <Column alignItems={'center'} justifyContent={'center'}>
                                <div data-swiper-parallax="-100">
                                    <ContentBlock background={'g_1'} className={'icon-container'}>
                                        <Image name={'roadmap_1.svg'} height={'100%'} />
                                    </ContentBlock>
                                </div>
                            </Column>
                        </Row>

                    </Container>
                </SwiperSlide>
                <SwiperSlide>
                    <Container>
                        <Row responsive>
                            <Column>
                                <div className="title" data-swiper-parallax="-500">
                                    2. Finanzielle Planung
                                </div>
                                <Space size={3} />
                                <div className="text" data-swiper-parallax="-100">
                                    <p>
                                        Sobald wir geeignete Immobilien identifiziert haben, arbeiten wir mit
                                        unseren Kunden zusammen, um einen maßgeschneiderten Finanzplan zu entwickeln.
                                        Dies umfasst die Sicherung der günstigsten Hypothekenoptionen mit wettbewerbsfähigen
                                        Zinssätzen, um die finanzielle Leistung der Investition zu optimieren.
                                    </p>
                                </div>
                            </Column>

                            <Column alignItems={'center'} justifyContent={'center'}>
                                <div data-swiper-parallax="-300">
                                    <ContentBlock background={'g_1'} className={'icon-container'}>
                                        <Image name={'roadmap_2.svg'} height={'100%'} />
                                    </ContentBlock>
                                </div>
                            </Column>
                        </Row>
                    </Container>

                </SwiperSlide>
                <SwiperSlide>
                    <Container>
                        <Row responsive>
                            <Column>
                                <div className="title" data-swiper-parallax="-500">
                                    3. Qualitätssicherung
                                </div>
                                <Space size={3} />
                                <div className="text" data-swiper-parallax="-100">
                                    <p>
                                        Alle Immobilien in unserem Portfolio unterliegen einer gründlichen Due-Diligence-Prüfung.
                                        Wir stellen sicher, dass jede Immobilie unseren strengen Qualitätsstandards entspricht, sei
                                        es durch Renovierungen oder Neubauten. Unser Ziel ist es, moderne, gut gepflegte Immobilien
                                        anzubieten, die zuverlässige Mieter anziehen.
                                    </p>
                                </div>
                            </Column>

                            <Column alignItems={'center'} justifyContent={'center'}>
                                <div data-swiper-parallax="-300">
                                    <ContentBlock background={'g_1'} className={'icon-container'}>
                                        <Image name={'roadmap_3.svg'} height={'100%'} />
                                    </ContentBlock>
                                </div>
                            </Column>
                        </Row>
                    </Container>

                </SwiperSlide>
                <SwiperSlide>
                    <Container>
                        <Row responsive>
                            <Column>
                                <div className="title" data-swiper-parallax="-500">
                                    4. Zusätzliche Dienstleistungen
                                </div>
                                <Space size={3} />
                                <div className="text" data-swiper-parallax="-100">
                                    <p>
                                        Neben dem Immobilienerwerb bieten wir eine Reihe von ergänzenden Dienstleistungen an, um das 
                                        Investitionserlebnis zu verbessern. Dazu gehören die Bereitstellung eines 1 m² großen "Hotte" 
                                        in jeder Immobilie, optionaler Mietausfallversicherung und professioneller Immobilienverwaltung.
                                    </p>
                                </div>
                            </Column>

                            <Column alignItems={'center'} justifyContent={'center'}>
                                <div data-swiper-parallax="-300">
                                    <ContentBlock background={'g_1'} className={'icon-container'}>
                                        <Image name={'roadmap_4.svg'} height={'100%'} />
                                    </ContentBlock>
                                </div>
                            </Column>
                        </Row>
                    </Container>

                </SwiperSlide>
                <SwiperSlide>
                    <Container>
                    <Row responsive>
                            <Column>
                                <div className="title" data-swiper-parallax="-500">
                                    5. Passive Investition
                                </div>
                                <Space size={3} />
                                <div className="text" data-swiper-parallax="-100">
                                    <p>
                                        Kunden haben die Möglichkeit, ihre Immobilien ausschließlich zu Investitionszwecken zu nutzen, ohne 
                                        aktives Management betreiben zu müssen. Unser Team kümmert sich um alle Aspekte der Immobilienverwaltung, 
                                        sodass die Kunden eine entspannte Investitionserfahrung genießen können.
                                    </p>
                                </div>
                            </Column>

                            <Column alignItems={'center'} justifyContent={'center'}>
                                <div data-swiper-parallax="-300">
                                    <ContentBlock background={'g_1'} className={'icon-container'}>
                                        <Image name={'roadmap_5.svg'} height={'100%'} />
                                    </ContentBlock>
                                </div>
                            </Column>
                        </Row>
                    </Container>

                </SwiperSlide>
                <SwiperSlide>
                    <Container>
                    <Row responsive>
                            <Column>
                                <div className="title" data-swiper-parallax="-500">
                                    6. Mietanpassungen
                                </div>
                                <Space size={3} />
                                <div className="text" data-swiper-parallax="-100">
                                    <p>
                                        Mietanpassungen sind alle drei Jahre möglich und bieten die Möglichkeit, die Mieteinnahmen 
                                        entsprechend den Marktkonditionen zu erhöhen.
                                    </p>
                                </div>
                            </Column>

                            <Column alignItems={'center'} justifyContent={'center'}>
                                <div data-swiper-parallax="-300">
                                    <ContentBlock background={'g_1'} className={'icon-container'}>
                                        <Image name={'roadmap_6.svg'} height={'100%'} />
                                    </ContentBlock>
                                </div>
                            </Column>
                        </Row>
                    </Container>

                </SwiperSlide>
            </Swiper>  
            </Reveal>          
        </Section>

    )
}