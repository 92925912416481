import { Image } from '../../components/common/Image'
import { Reveal } from '../../components/Reveal'
import { LoadingOutlined } from '@ant-design/icons'

import './index.scss'

export const Loading = () => {
    return (
        <div className='loading-container'>
            <Reveal>
            <LoadingOutlined style={{ fontSize: '82px', color: 'white' }} />
            </Reveal>
        </div>
    )
}